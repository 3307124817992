import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BackgroundImage from "gatsby-background-image"

import LayoutContent from "./layout-content.js"
import layoutBackgroundBurritoStyles from "../styles/layout-background-burrito.module.scss"

const LayoutBackgroundBurrito = props => {
  const data = useStaticQuery(graphql`
    query {
      indexImage: file(
        relativePath: { eq: "background/HungryTurtle_Background_Burrito.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.indexImage.childImageSharp.fluid}
      style={{
        backgroundAttachment: 'fixed',
      }}
    >
        <div className={layoutBackgroundBurritoStyles.blackOverlay}>
            <LayoutContent>
                {props.children}
            </LayoutContent>
        </div>
    </BackgroundImage>
  )
}

export default LayoutBackgroundBurrito